<template>
  <!-- Loading -->
  <div
    v-if="loading"
    class="d-flex justify-center"
  >
    <v-progress-circular
      :size="50"
      indeterminate
      color="primary"
    >
    </v-progress-circular>
  </div>
  <div v-else>
    <div>
      <v-app-bar
        color="transparent"
        elevation="0"
      >
        <div class="mt-3">
          <AppBarI18n></AppBarI18n>
        </div>

        <v-spacer></v-spacer>

        <a
          href="https://www.anita-lee.com"
          target="_blank"
        >
          <div
            dir="ltr"
            class="d-flex"
          >
            <v-img
              :src="appLogo"
              alt="logo"
              contain
              eager
              max-height="35px"
              max-width="35px"
            ></v-img>

            <v-img
              :src="appName"
              alt="name"
              contain
              eager
              max-height="40px"
              max-width="120px"
              class="mt-2"
            ></v-img>
          </div>
        </a>
      </v-app-bar>
    </div>
    <div
      class="align-center justify-center d-flex"
      style="height: 80vh"
    >
      <v-card
        class="pa-4 pt-7"
        max-width="448"
      >
        <v-card-text class="pt-5 pb-1">
          <h5 class="text-h5 mb-1">
            <v-icon color="primary">
              {{ icons.mdiLock }}
            </v-icon>
            {{ $t('ForgotPassword') }}
          </h5>

          <div class="pt-2">
            {{ $t('ForgotPasswordMsg') }}
          </div>
        </v-card-text>
        <v-card-text class="mt-4">
          <v-form
            ref="emailForm"
            @submit.prevent="resendEmail"
          >
            <v-text-field
              v-model="email"
              outlined
              :rules="[validators.required ,validators.emailValidator]"
              :label="$t('Email')"
              placeholder="userName@example.com"
              hide-details="auto"
              dense
              class="mb-4"
            ></v-text-field>

            <v-btn
              block
              color="primary"
              type="submit"
            >
              {{ $t('ChangePassword') }}
            </v-btn>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <router-link
            :to="{name:'auth-login'}"
            class="d-flex align-center text-sm"
          >
            <span>{{ $t('BackToLogin') }}</span>
            <v-icon
              size="24"
              color="primary"
            >
              {{ icons.mdiChevronLeft }}
            </v-icon>
          </router-link>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>
<script>
import { i18n } from '@/plugins/i18n/index'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import { emailValidator, required } from '@core/utils/validation'
import { mdiChevronLeft, mdiLock } from '@mdi/js'
import themeConfig from '@themeConfig'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'

export default {
  components: { AppBarI18n },
  setup() {
    const email = ref()
    const emailForm = ref(null)
    const loading = ref(false)
    const toast = useToast()
    const showToast = () => toast.error(i18n.t('errorManageEvents'))

    const resendEmail = () => {
      const isFormValid = emailForm.value.validate()

      if (!isFormValid) return

      loading.value = true
      store
        .dispatch('sendResetPassword', { email: email.value, lang: i18n.locale })
        .catch(() => showToast(i18n.t('errorManageEvents')))
        .finally(() => {
          loading.value = false
        })
    }

    return {
      resendEmail,
      loading,
      email,
      emailForm,
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiLock,
        mdiChevronLeft,
      },
      validators: {
        emailValidator,
        required,
      },
    }
  },
}
</script>
