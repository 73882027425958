import { guestStatusMapping } from '@/composables/useGuestStatuses'
import { i18n } from '@/plugins/i18n/index'
import { isEmpty } from './index'

export const required = value => (value && value.length ? true : i18n.t('FieldRequired'))
export const requiredNumber = value => (value > 0 ? true : i18n.t('FieldRequired'))
export const validateInviteeNumber = value =>
  Number(value) > 0 && Number(value) < 11 ? true : i18n.t('countOfInvitesMustBeUntil10')
export const emailValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  // eslint-disable-next-line
  const re =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (Array.isArray(value)) {
    return value.every(val => re.test(String(val)))
  }

  return re.test(String(value)) || i18n.t('NotValidEmail')
}

export const passwordValidator = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /^(?=.*\d|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).{4,20}$/

  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)

  return (
    // eslint-disable-next-line operator-linebreak
    validPassword || i18n.t('WeakPassword')
  )
}

export const confirmedValidator = (value, target) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  value === target || 'The Confirm Password field confirmation does not match'

export const between = (value, min, max) => () => {
  const valueAsNumber = Number(value)

  return (Number(min) <= valueAsNumber && Number(max) >= valueAsNumber) || `Enter number between ${min} and ${max}`
}

export const integerValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  if (Array.isArray(value)) {
    return value.every(val => /^-?[0-9]+$/.test(String(val)))
  }

  return /^-?[0-9]+$/.test(String(value)) || i18n.t('RequireNum')
}

export const regexValidator = (value, regex) => {
  if (isEmpty(value)) {
    return true
  }

  let regeX = regex
  if (typeof regeX === 'string') {
    regeX = new RegExp(regeX)
  }

  if (Array.isArray(value)) {
    return value.every(val => regexValidator(val, { regeX }))
  }

  return regeX.test(String(value)) || 'The Regex field format is invalid'
}

export const alphaValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  // const valueAsString = String(value)

  return /^[A-Z]*$/i.test(String(value)) || 'The Alpha field may only contain alphabetic characters'
}

export const urlValidator = value => {
  if (value === undefined || value === null || value.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/

  return re.test(value) || 'URL is invalid'
}

export const lengthValidator = (value, length) => {
  if (isEmpty(value)) {
    return true
  }

  return value.length === length || `The Min Character field must be at least ${length} characters`
}
export const alphaDashValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  const valueAsString = String(value)

  return /^[0-9A-Z_-]*$/i.test(valueAsString) || 'All Character is not valid'
}

export const maxLenInput = value => {
  if (isEmpty(value)) {
    return true
  }

  return value.length <= 40 ? true : i18n.t('MaxField', { num: '40' })
}

export const minLenInput = value => {
  if (isEmpty(value)) {
    return true
  }

  return value.length >= 2 ? true : i18n.t('MinField', { num: '2' })
}

export const minUserFullName = value => {
  if (isEmpty(value)) {
    return true
  }

  return value.length >= 4 ? true : i18n.t('MinField', { num: '4' })
}

export const maxPhoneNumber = value => {
  if (isEmpty(value)) {
    return true
  }

  return value.length <= 10 ? true : i18n.t('MaxField', { num: '10' })
}

export const maxLenMessage = value => {
  if (isEmpty(value)) {
    return true
  }

  return value.length <= 300 ? true : i18n.t('MaxField', { num: '300' })
}
export const maxCallLenMessage = value => {
  if (isEmpty(value)) {
    return true
  }

  return value.length <= 250 ? true : i18n.t('MaxField', { num: '250' })
}

export const minLenMessage = value => {
  if (isEmpty(value)) {
    return true
  }

  return value.length >= 20 ? true : i18n.t('MinField', { num: '20' })
}
export const checkConfirmPassword = (newPassword, confirmPassword) => {
  if (isEmpty(newPassword || confirmPassword)) {
    return true
  }

  return newPassword === confirmPassword ? true : i18n.t('ConfirmPasswordNotMatch')
}
export const attendingNotZero = (guestStatus, attendingAmount) => {
  if (isEmpty(guestStatus || attendingAmount)) {
    return true
  }

  // If status is attending, check if attending amount is greater than 0.
  if (guestStatus === guestStatusMapping.COMING) {
    if (attendingAmount > 0) {
      return true
    }

    return i18n.t('AttendingNotZero')
  }

  return true
}
export const invalidPhoneNumber = value => {
  if (isEmpty(value)) {
    return true
  }
  if (value.slice(0, 2) === '05' || value.slice(0, 2) === '07') {
    return value.length <= 10 ? true : i18n.t('invalidPhoneNumber')
  }
  if (value.slice(0, 1) === '0') {
    return value.length <= 9 ? true : i18n.t('invalidPhoneNumber')
  }

  return i18n.t('invalidPhoneNumber')
}

// Validate exact 10 char
export const PhoneNumberLength = value => {
  if (isEmpty(value)) {
    return true
  }
  if (value.slice(0, 2) === '05' || value.slice(0, 2) === '07') {
    return value.length === 10 ? true : i18n.t('invalidPhoneNumber')
  }
  if (value.slice(0, 1) === '0') {
    return value.length === 9 ? true : i18n.t('invalidPhoneNumber')
  }

  return i18n.t('invalidPhoneNumber')
}
