// eslint-disable-next-line object-curly-newline
import {
  mdiAccountCheckOutline,
  mdiAccountClock,
  mdiAccountOutline,
  mdiAccountRemoveOutline,
  mdiAlertCircleOutline,
  mdiEye,
} from '@mdi/js'

export const guestStatusMapping = {
  PENDING: 0,
  IN_PROGRESS: 1,
  COMING: 2,
  NOT_COMING: 3,
  WRONG_NUMBER: 4,

  PARTIALLY_COMING: 5, // Only for filter
}

export default function useGuestStatuses() {
  const statusOptions = [
    {
      title: 'viewCount',
      color: 'blue',
      icon: mdiEye,
    },
    {
      title: 'inProgress',
      value: guestStatusMapping.IN_PROGRESS,
      color: 'primary',
      icon: mdiAccountOutline,
    },
    {
      title: 'pending',
      value: guestStatusMapping.PENDING,
      color: 'secondary',
      icon: mdiAccountClock,
    },
    {
      title: 'approved',
      value: guestStatusMapping.COMING,
      color: 'success',
      icon: mdiAccountCheckOutline,
    },
    {
      title: 'partiallyComing',
      value: guestStatusMapping.PARTIALLY_COMING,
    },
    {
      title: 'declined',
      value: guestStatusMapping.NOT_COMING,
      color: 'error',
      icon: mdiAccountRemoveOutline,
    },
    {
      title: 'wrongNumber',
      value: guestStatusMapping.WRONG_NUMBER,
      color: 'lightRed',
      icon: mdiAlertCircleOutline,
    },
  ]

  return {
    statusOptions,
    guestStatusMapping,
  }
}
